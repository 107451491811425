
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import EngineContent from '@/components/forms/inspection/steppers/content/EngineContent.vue'
  import RefrigerantContent from '@/components/forms/inspection/steppers/content/RefrigerantContent.vue'
  import BatteryContent from '@/components/forms/inspection/steppers/content/BatteryContent.vue'
  import CarExhaustContent from '@/components/forms/inspection/steppers/content/CarExhaustContent.vue'
  import BrakesContent from '@/components/forms/inspection/steppers/content/BrakesContent.vue'
  import CarDirectionContent from '@/components/forms/inspection/steppers/content/CarDirectionContent.vue'
  import CarShockAbsorberContent from '@/components/forms/inspection/steppers/content/CarShockAbsorberContent.vue'
  import ClutchContent from '@/components/forms/inspection/steppers/content/ClutchContent.vue'
  import TransmissionContent from '@/components/forms/inspection/steppers/content/TransmissionContent.vue'
  import TractionContent from '@/components/forms/inspection/steppers/content/TractionContent.vue'
  import ScannerContent from '@/components/forms/inspection/steppers/content/electric/ScannerContent.vue'
  import EngineSupervisorContent
    from '@/components/forms/inspection/supervisor/content/mechanical/EngineSupervisorContent.vue'
  import RefrigerantSupervisorContent
    from '@/components/forms/inspection/supervisor/content/mechanical/RefrigerantSupervisorContent.vue'
  import BatterySupervisorContent
    from '@/components/forms/inspection/supervisor/content/mechanical/BatterySupervisorContent.vue'
  import CarExhaustSupervisorContent
    from '@/components/forms/inspection/supervisor/content/mechanical/CarExhaustSupervisorContent.vue'
  import BrakesSupervisorContent
    from '@/components/forms/inspection/supervisor/content/mechanical/BrakesSupervisorContent.vue'
  import CarDirectionSupervisorContent
    from '@/components/forms/inspection/supervisor/content/mechanical/CarDirectionSupervisorContent.vue'
  import CarShockAbsorberSupervisorContent
    from '@/components/forms/inspection/supervisor/content/mechanical/CarShockAbsorberSupervisorContent.vue'
  import ClutchSupervisorContent
    from '@/components/forms/inspection/supervisor/content/mechanical/ClutchSupervisorContent.vue'
  import TransmissionSupervisorContent
    from '@/components/forms/inspection/supervisor/content/mechanical/TransmissionSupervisorContent.vue'
  import TractionSupervisorContent
    from '@/components/forms/inspection/supervisor/content/mechanical/TractionSupervisorContent.vue'
  import { isValidNumber } from '@/utils/general'

@Component({
  methods: { isValidNumber },
  components: {
    TractionSupervisorContent,
    TransmissionSupervisorContent,
    ClutchSupervisorContent,
    CarShockAbsorberSupervisorContent,
    CarDirectionSupervisorContent,
    BrakesSupervisorContent,
    CarExhaustSupervisorContent,
    BatterySupervisorContent,
    RefrigerantSupervisorContent,
    EngineSupervisorContent,
    ScannerContent,
    TractionContent,
    TransmissionContent,
    ClutchContent,
    CarShockAbsorberContent,
    CarDirectionContent,
    BrakesContent,
    CarExhaustContent,
    BatteryContent,
    RefrigerantContent,
    EngineContent,
    RatingCell,
    GFiles,
    GFormSlot,
    BasePanel,
  },
})
  export default class MechanicalPanel extends BaseStepper {
  declare $refs: {
    form: HTMLFormElement,
    engine: EngineSupervisorContent
    refrigerant: RefrigerantSupervisorContent
    battery: BatterySupervisorContent
    carExhaust: CarExhaustSupervisorContent
    brakes: BrakesSupervisorContent
    carDirection: CarDirectionSupervisorContent
    carShockAbsorber: CarShockAbsorberSupervisorContent
    clutch: ClutchSupervisorContent
    transmission: TransmissionSupervisorContent
    traction: TractionSupervisorContent
  };

  componentKeys = [
    'engine', 'refrigerant', 'battery', 'carExhaust', 'carLeaks', 'brakes', 'carDirection', 'carShockAbsorber', 'clutch', 'traction',
  ]

  traction = null
  customStep = 8
  total = null

  formData = {
    engine: {
      total: 0,
    },
    refrigerant: {
      total: 0,
    },
    battery: {
      total: 0,
    },
    carExhaust: {
      total: 0,
    },
    carLeaks: {
      total: 0,
    },
    brakes: {
      total: 0,
    },
    carDirection: {
      total: 0,
    },
    carShockAbsorber: {
      total: 0,
    },
    clutch: {
      total: 0,
    },
    traction: {
      total: 0,
    },
    transmission: {
      total: 0,
    },
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get tractionValues () {
    return this.findComponentBySlug(this.components, 'traction')
  }

  get transmission () {
    return this.findComponentBySlug(this.components, 'transmission')
  }

  get clutch () {
    return this.findComponentBySlug(this.components, 'clutch')
  }

  get carShockAbsorber () {
    return this.findComponentBySlug(this.components, 'car_shock_absorber')
  }

  get carDirection () {
    return this.findComponentBySlug(this.components, 'car_direction')
  }

  get brakes () {
    return this.findComponentBySlug(this.components, 'brakes')
  }

  get carLeaks () {
    return this.findComponentBySlug(this.components, 'car_leaks')
  }

  get carExhaust () {
    return this.findComponentBySlug(this.components, 'car_exhaust')
  }

  get battery () {
    return this.findComponentBySlug(this.components, 'battery')
  }

  get engine () {
    return this.findComponentBySlug(this.components, 'engine')
  }

  get refrigerant () {
    return this.findComponentBySlug(this.components, 'refrigerant')
  }

  get isTraction () {
    const { generation, traction } = this

    return Boolean(generation?.traction?.id) && Boolean(traction?.id)
  }

  get isTransmissionAutomatic () {
    return this.generation?.transmission?.val === 'Automática'
  }

  get tractionAndGenerationData () {
    const { generation, tractionValues } = this
    return {
      generation,
      tractionValues,
    }
  }

  @Watch('tractionAndGenerationData', { immediate: true, deep: true })
  onTractionValues (val) {
    if (!val?.tractionValues?.id || !val?.generation?.id) return

    this.traction = val?.tractionValues.values.find(value => value.id === val?.generation?.traction?.componentValue?.id)
    if (val?.generation.transmission?.val === 'Automática') {
      this.customStep = 7
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  @Watch('inspection', { immediate: true, deep: true })
  onInspectionDataChange (val) {
    const { category } = this

    if (!isValidNumber(this.total)) {
      this.total = val?.metadata?.findCategoryByName(category.name).total
    }
  }

  async send () {
    const { categoryName, category } = this
    const keys = ['engine', 'refrigerant', 'battery', 'carExhaust', 'brakes', 'carDirection', 'carShockAbsorber', 'clutch', 'traction', 'transmission']

    let total = 0

    for (const key of keys) {
      if (this.$refs[key]?.send()) {
        const answer = await this.$refs[key].send()
        if (answer === false) return false
        total += answer
      }
    }
    this.total = total
    this.$emit('input', { categoryName, category, total })
    this.$emit('inspection-category-done')
  }
  }
